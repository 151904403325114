import { Action } from '@ngrx/store';
import { type } from '../../../util';

export const ActionTypes = {
  REDIRECT_COMPLETED: type('[Implicit i9] redirect completed'),
};

export class I9RedirectCompleted implements Action {
  public type = ActionTypes.REDIRECT_COMPLETED;

  constructor(public payload?: any) {}
}

export type TypeAction = I9RedirectCompleted;
